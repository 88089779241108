import {Button, Center, Checkbox, Group, Loader, Select, Skeleton, Text, TextInput} from '@mantine/core';
import { FC, useContext, useState } from 'react';
import { i18n } from '@lingui/core';
import { IconChevronDown } from '@tabler/icons';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import { DataS } from '../../../Service/DataS';
import ScheduleMeetingAlert from '../ContactFormBuilder/ScheduledMettingAlert';
import { useMailOutBoundConfig } from '../../../Service/hooks/useMailOutboundConfig';
import { ProviderHint } from '../../../Types/MailT';
import MailHint from './MailHint';
import AzureAuthentication from './oauth/AzureOAuthPane';

const MailOutBoundConfig: FC<{ id: number }> = ({ id }) => {
  const { profile } = useContext(ProfileContext);
  const [oauthConnected, setOauthConnected] = useState(false);
  const {
    config: mailOutBoundConfig,
    loading,
    handleChange,
    sendMail,
    sendingEmail,
    sentFailed,
    providerHint,
  } = useMailOutBoundConfig(id);

  if (loading) return <Skeleton width="100%" height={600} />;
  if (!mailOutBoundConfig) return null;

  const { provider, description, email, smptHost, smptPort, login, password, auth, startTls, bcc } = mailOutBoundConfig;
  const oauthIncluded = (provider === 'office365' || provider === 'outlook' || provider === 'hotmail');

  return (
    <>
      <Select
        required
        searchable
        label="E-Mail-Anbieter"
        value={provider}
        styles={{ rightSection: { pointerEvents: 'none' } }}
        rightSection={<IconChevronDown size={16} />}
        rightSectionWidth={25}
        onChange={(val: string) => {
          handleChange({
            provider: val,
            defaultAccount: false,
          });
        }}
        data={DataS.getMailProviderOptions(i18n)}
      />
      {!oauthIncluded && (
        <Group grow mt="sm">
          <TextInput
            required
            label="SMTP-Host"
            defaultValue={smptHost}
            onBlur={(e) => handleChange({ smptHost: e.currentTarget.value })}
          />
          <Select
            required
            label="SMTP-Port"
            clearable
            defaultValue={smptPort ? smptPort.toString() : ''}
            onChange={(val: string) => {
              handleChange({
                smptPort: Number(val),
              });
            }}
            data={DataS.getMailPortOptions()}
          />
        </Group>
      )}
      <TextInput
        mt="sm"
        label="Beschreibung (nur für Dich)"
        defaultValue={description}
        onBlur={(e) => handleChange({ description: e.currentTarget.value })}
      />
      <MailHint hint={providerHint as ProviderHint} />
      {(!oauthIncluded || (oauthIncluded && oauthConnected)) && <TextInput
        required
        mt="xs"
        label="E-Mail-Adresse"
        defaultValue={email}
        onBlur={(e) => handleChange({email: e.currentTarget.value})}
      />}
      <TextInput
        my="sm"
        label="Blind-Kopie-E-Mail (BCC)"
        defaultValue={bcc}
        onBlur={(e) => handleChange({ bcc: e.currentTarget.value })}
      />
      {profile && (
        <TextInput
          disabled
          mb="sm"
          label="Antwort-E-Mail-Adresse"
          defaultValue={`profile-${profile.id}@bookitup.de`}
          style={{ color: '#000000' }}
        />
      )}
      <Text italic color="dimmed" size="xs" weight="lighter" mt="md">
        Mit dieser Antwort-E-Mail-Adresse werden alle Antworten deiner Kunden sortiert in bookitup abgelegt. Kann bei Bedarf durch den Support deaktiviert werden.
      </Text>
      {!oauthIncluded && (
        <Group my="xs">
          <Checkbox
            size="xs"
            checked={startTls}
            label="StartTLS"
            onChange={(e) => handleChange({ startTls: e.currentTarget.checked })}
          />
          <Checkbox
            size="xs"
            checked={auth}
            label="SSL-Verschlüsselt"
            onChange={(e) => handleChange({ auth: e.currentTarget.checked })}
          />
        </Group>
      )}

      {oauthIncluded ? (
        <AzureAuthentication setOauthConnected={setOauthConnected} />
      ) : (
        <>
          <TextInput
            required
            mb="sm"
            label="Benutzername"
            defaultValue={login}
            onBlur={(e) => handleChange({ login: e.currentTarget.value })}
          />
          <TextInput
            required
            label="Passwort"
            type="password"
            defaultValue={password}
            onBlur={(e) => handleChange({ password: e.currentTarget.value })}
          />
        </>
      )}
      {sentFailed && <ScheduleMeetingAlert />}
      {!(oauthIncluded && !oauthConnected) && (
        <Center mt="xl">
          <Button
            disabled={sendingEmail}
            mt="xl"
            sx={{ minWidth: 200 }}
            onClick={sendMail}
            leftIcon={sendingEmail && <Loader size="xs" />}
          >
            Test-E-Mail verschicken
          </Button>
        </Center>
      )}
    </>
  );
};

export default MailOutBoundConfig;
